import request from "@/util/request";

export function getAdd(data) {
  console.log(data);

  return request({
    url: "/system/details/add",
    method: "post",
    data: data,
  });
}
