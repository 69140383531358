<template>
  <div class="popup-overlay" v-if="visible" @click="closePopup">
    <div class="popup-content">
      <img :src="require('@/assets/national.png')" alt="许可证" />
    </div>
  </div>
</template>

<script>
export default {
  name: "ImagePopup",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  max-width: 90%;
  max-height: 90%;
}

.popup-content img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
</style>
